// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// Simple & lightweight library to create smooth & beautiful animations when you scroll.
import lax from 'lax.js';


// import PanelSnap from 'panelsnap';

import createScrollSnap from 'scroll-snap';


// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import {
    createApp, ref, onMounted, watch,
} from 'vue';
import { useWindowScroll } from '@vueuse/core';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@headlessui/vue';

import LazyHeader from '@components/LazyHeader.vue';
import LaxScrollAnimation from '@components/LaxScrollAnimation.vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        LaxScrollAnimation,
        LazyHeader,
    },

    provide() {
        lax.init();

        lax.addDriver('scrollY', () => {
            return window.scrollY;
        });

        return {
            lax,
        };

    },

    setup() {
        const searchHidden = ref(true);
        const menuHidden = ref(true);

        return {
            searchHidden,
            menuHidden,
        };
    },

    mounted() {
        console.log('Mounted');

        // const element = document.getElementById('snap-content');

        // createScrollSnap(element, {
        //     snapDestinationX: '0%',
        //     snapDestinationY: '90%',
        //     timeout: 100,
        //     duration: 300,
        //     threshold: 0.2,
        //     snapStop: false,
        //     // easing: easeInOutQuad,
        // }, () => { return console.log('element snapped'); });

        // createScrollSnap();

        // const options = {
        //     // container: document.body,
        //     container: document.getElementById('snapcontainer'),
        //     panelSelector: '.section',
        //     directionThreshold: 200,
        //     delay: 0,
        //     duration: 3000,
        //     easing(t) { return t; },
        // };

        // new PanelSnap(options);
    },


}).mount('#app');
