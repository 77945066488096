<template>
    <slot :show-header="showHeader" :scroll-y-pos="scrollYPos"></slot>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useWindowScroll, useRafFn } from '@vueuse/core';

const props = defineProps({
    threshold: {
        type: Number,
        default: 20,
    },
});

const showHeader = ref(true);
const initialScrollPos = ref(0);

const { y: scrollYPos } = useWindowScroll();

onMounted(() => {
    initialScrollPos.value = scrollYPos.value;
});

useRafFn(() => {
    if (scrollYPos.value === initialScrollPos.value) {
        return;
    }

    if (scrollYPos.value > 0 && scrollYPos.value > initialScrollPos.value + props.threshold) {
        showHeader.value = false;

        initialScrollPos.value = scrollYPos.value;
    }

    if (scrollYPos.value < initialScrollPos.value - props.threshold) {
        showHeader.value = true;

        initialScrollPos.value = scrollYPos.value;
    }
});

</script>
